import '../Components.css';
import Decline from './svg/Decline.svg';



const Oncall = (props)=>{
    const {refDecline} = props;
    return(
        <div className='Control'>
            <div><button className='Decline' ref={refDecline}><img src={Decline} className="Card-log" alt="logo" /></button></div>
        </div>
    );
}
export default Oncall;


