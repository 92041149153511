// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Page{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height:100%;
    background-color: rgba(216, 216, 216, 0.466);
}
.Modal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height:100%;
    padding: 2vmin;
    

}
.Warning{
    background-color: #2f2e33;
    border-radius: 15px;
    margin-top: 2em;
    color: white;
    font-weight: bold;
    padding: 1vmin;
    width: 25vmin;
}
.Warning:hover{
    background-color: #5a5d5f;

}`, "",{"version":3,"sources":["webpack://./src/pages/Pages.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;IACX,4CAA4C;AAChD;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;IACX,cAAc;;;AAGlB;AACA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;IACf,YAAY;IACZ,iBAAiB;IACjB,cAAc;IACd,aAAa;AACjB;AACA;IACI,yBAAyB;;AAE7B","sourcesContent":[".Page{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n    height:100%;\n    background-color: rgba(216, 216, 216, 0.466);\n}\n.Modal{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n    height:100%;\n    padding: 2vmin;\n    \n\n}\n.Warning{\n    background-color: #2f2e33;\n    border-radius: 15px;\n    margin-top: 2em;\n    color: white;\n    font-weight: bold;\n    padding: 1vmin;\n    width: 25vmin;\n}\n.Warning:hover{\n    background-color: #5a5d5f;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
