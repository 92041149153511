import logo from './logo_Hospitality.svg';
import powered from './Powered.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Agent from './pages/Agent';
import Guest from './pages/Guest';

const App = ()=> {
  
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <div className='Divider'/>
      <div className='PageLoad'>
        <Routes>
          <Route exact path="/" element={<Guest/>}/>
          <Route exact path="/Agent" element={<Agent/>}/>
        </Routes>
      </div>
      <div className='Divider'/>
      <header className='Footer'>
        <img src={powered} className="Powered-logo" alt="logo" />
      </header>
      
    </div>
  );
}

export default App;
