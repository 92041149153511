import '../Components.css';
import CustomerCare from './svg/CustomerCare.svg';

const Cardguest = (props)=>{
    const {status,timeInf} = props;
    return(
        <div className="Card">
            <div className='Name'>
                <h2>{status}</h2>  
                <h3>{timeInf}</h3>    
            </div>
            <img src={CustomerCare} className="Card-logo" alt="logo" />           
        </div>
    )
}

export default Cardguest;