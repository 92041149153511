import ringtone from "./audio/ringtone.wav";
import callertone from "./audio/callertone.mpga";

const ring = new Audio(ringtone);
const caller = new Audio(callertone);

export const playRingtone = ()=>{
    ring.currentTime = 0;
    ring.addEventListener('ended',()=>{
        ring.currentTime = 0;
        ring.play();

    });
    ring.play();
};

export const stopRingtone = ()=>{
    ring.pause();
};

export const playCallertone = ()=>{
    caller.currentTime = 0;
    caller.addEventListener('ended',()=>{
        caller.currentTime = 0;
        caller.play();

    });
    caller.play();
};

export const stopCallertone = ()=>{
    caller.pause();
};



