import {Peer} from "peerjs";
import io from 'socket.io-client';
import Modal from 'react-modal';


import './Pages.css';
import Dialer from './components/Dialer';
import { useEffect , useState , useRef , useCallback , useMemo } from 'react';
import { playCallertone , stopCallertone } from './components/gadgets/tunes';

Modal.setAppElement('#root');
const WS = io.connect("https://webcallingpeer.apac.hospitalitywifi.net");
let peer = new Peer(
    {config: {'iceServers': [
        { url: 'turn:relay1.expressturn.com:3478', username: 'efD1O7K3U7NAD33WDN', credential: 'apR5J103e381jF90'}       
  ],
  
}
});
let stream = null;
let timer = null;




const Guest = ()=>{
    //States
    
    const [statusCode,setStatusCode] = useState(0);
    const [status,setStatus] = useState("");
    //Guest Data
    const [name,setName] = useState('');
    const [hotel,setHotel] = useState('');
    const [room, setRoom] = useState('');
    //Modal State
    const [isOpen , setOpen] = useState(false);
    const [timeInf,setTimeInf] = useState("");
    const [isLoading,setLoading] = useState(false);
    


    const form = useMemo(()=>{
        return  {
            name : name,
            hotel : hotel,
            room : room
        }
    },[hotel,name,room]);

    const setForm = {
        setName: setName,
        setHotel: setHotel,
        setRoom: setRoom

    };

    //Refs
    const refDecline = useRef(null);
    const refCall = useRef(null);
    const refSubmit = useRef(null);
    const audioRef = useRef(null);
    const peerRef = useRef(null);
    const callRef = useRef(null);
    const refReject = useRef(null);
    
    
    



    //Functions
    
    const stopStream = ()=>{
        if(stream !== null){
            stream.getAudioTracks().forEach((audioTrack)=>{
                audioTrack.stop();
            });
        }
        

    };

    const makeDecline = useCallback(()=>{
        const call = callRef.current;
        if(call){
            call.close();
            stopStream();
            WS.emit("makeDecline");
            setStatus("Thank you.. Call again ");
            setStatusCode(4);
            setTimeout(()=>{
                window.location.reload();
            },3000);
        }else{
            WS.emit("makeReject");
            setStatus("Agent Busy");
            setStatusCode(1);
        }
        
        stopCallertone();


    },[]);

    const makeCall = useCallback(()=>{
        WS.emit("makeCall");
        setStatus("Calling");
        setStatusCode(2);
        playCallertone();

    },[]);
    const makeSubmit = useCallback(()=>{
        if(name === "" || hotel === ""|| room === ""){
            setOpen(true);
        }else{
            WS.emit("Submit",form);
            setOpen(false);
            setLoading(true);
        }
        
    },[hotel,name,room,form]);
    const closeModal = ()=>{
        setOpen(false);
    };

    useEffect(()=>{
        //Click Events
        peerRef.current = peer;
        switch(statusCode){
            case 0:
                refSubmit.current.addEventListener('click',makeSubmit);
                break;
            case 1:
                refCall.current.addEventListener('click',makeCall);
                break;
            case 2:
                refReject.current.addEventListener('click',makeDecline);
                break;
            case 3:
                refDecline.current.addEventListener('click',makeDecline);
                break;
            default:
                break;
        }
        peer.on('open',(id)=>{
            WS.emit("peer",id);
        });
        WS.on("On-Submit",()=>{   
            setStatusCode(1);
            setStatus("Ready To Talk");
            setLoading(false);

        });
        WS.on("reqAnswer", ()=>{
            setStatusCode(3);
            stopCallertone();
            setStatus("Answered");
            let min = 0;
            let sec = 0;
            timer = setInterval(()=>{
                let minStr = (min < 10) ? `0${min}`: `${min}`;
                let secStr = (sec < 10) ? `0${sec}`: `${sec}`;
                setTimeInf(`${minStr}:${secStr}`);
                sec += 1;
                if ( sec === 60){
                    min += 1;
                    sec = 0;
                }

            },1000);                   
        });

        WS.on("reqReject", ()=>{
            setStatus("Agent Busy");
            setStatusCode(1);
            stopCallertone();
        });

        WS.on("reqDecline", ()=>{
            clearInterval(timer);
            setStatus("Thank you.. Call again ");
            setStatusCode(4);
            stopStream();
            stopCallertone();
            setTimeout(()=>{
                window.location.reload();

            },3000);
            
            
        });
        WS.on("null",()=>{
            setTimeout(stopCallertone,1000);
            setStatus("Unavailable Agents");
            setStatusCode(1);

        });
        WS.on("disconnect",()=>{
            window.location.reload();
            
        });
        
        peer.on('call',(call)=>{
            callRef.current = call; 
            navigator.mediaDevices.getUserMedia({audio:true}).then((mediaStream)=>{
                call.answer(mediaStream);
                stream = mediaStream;
                call.on('stream',(remoteStream)=>{
                    audioRef.current.srcObject = remoteStream;
        
            // To avoid collision during multiple streams
                    audioRef.current.addEventListener('loadedmetadata', () => {
                        audioRef.current.play();
                    });
        
                })
                
                }).catch((err)=>{
                    console.log(err);
                });
                
          });

    },[statusCode,makeCall,makeDecline,makeSubmit]);



    return(
        <div className='Page'>
            
            <Dialer statusCode={statusCode} 
            form={form}
            setForm={setForm}
            refSubmit={refSubmit}
            status={status}
            refCall={refCall}
            refDecline={refDecline}
            refReject={refReject}
            timeInf={timeInf}
            isLoading={isLoading}
            isOpen={isOpen}
            closeModal={closeModal}/>

            
        <audio ref={audioRef} />
        </div>
    );
}

export default Guest;