import '../Components.css';
import Guest from './svg/Guest.svg';

const Cardagent = (props)=>{
    const {status , guestData,timeInf} = props;
    const {name , hotel , room} = guestData;
    return(
        <div className="Card">
            <div className='Name'>
                <h2>{status}</h2>
                <h3>{timeInf}</h3>
                <h5>{name}</h5>
                <h5>{hotel}</h5>
                <h5>{room}</h5>
            </div>
            <img src={Guest} className="Card-logo" alt="logo" />  
        </div>
    )
}

export default Cardagent;