import '../Components.css';
import ActiveState from './svg/Active.svg';
import AwayState from './svg/Away.svg';


const Active = (props)=>{
    const {isActive,refActive} = props;
    return(<div className='Control'>
        <button className='Active' ref={refActive} >{(isActive) ? (<img src={ActiveState} className="Card-logo" alt="logo" />): (<img src={AwayState} className="Card-logo" alt="logo" />)}</button>

    </div>)
};

export default Active;

