import {Peer} from "peerjs";
import io from 'socket.io-client';
import Modal from 'react-modal';


import './Pages.css';
import Receiver from './components/Receiver';
import { useEffect , useState , useRef,useCallback } from 'react';
import { playRingtone , stopRingtone } from './components/gadgets/tunes';

Modal.setAppElement('#root');
const WS = io.connect("https://webcallingpeer.apac.hospitalitywifi.net");
let peer = new Peer(
    {config: {'iceServers': [
        { url: 'turn:relay1.expressturn.com:3478', username: 'efD1O7K3U7NAD33WDN', credential: 'apR5J103e381jF90'}    
  ],
  
}
});
let stream = null;
let timer = null;



const Agent = ()=>{
    //Ref
    const refLogin = useRef(null);
    const refAnswer = useRef(null);
    const refReject = useRef(null);
    const refActive = useRef(null);
    const refDecline = useRef(null);
    //peer calling
    const audioRef = useRef(null);
    const peerRef = useRef(null);
    const callRef = useRef(null);
    
    //States
    const [statusCode,setStatusCode] = useState(0);
    const [token,setToken] = useState('');
    const [status,setStatus] = useState('');
    const [isActive, setActive] = useState(false);
    const [isAnswered, setAnswered] = useState(false);
    const [guestData, setGuestData] = useState({
        name : "",
        hotel : "",
        room : ""
    });
    const [remote , setRemote] = useState({
        socketId : "",
        peerId : ""
    });

    const [usr , setUsr] = useState('');
    const [timeInf,setTimeInf] = useState("");

    //Modal State
    const [isOpen , setOpen] = useState(false);
    const [isLoading,setLoading] = useState(false);



    const makeDecline = useCallback(()=>{
        const call = callRef.current;
        if(call){
            call.close();
            stopStream();
            WS.emit("makeDecline");
        }else{
            WS.emit("makeReject",remote.socketId);
        }
        setGuestData({
            name : "",
            hotel: "",
            room: ""
        });
        setStatusCode(1);
        setStatus(`Hello ${usr}`);
        setRemote({
            socketId : "",
            peerId : ""
        });
        stopRingtone();
        setAnswered(false);
    },[remote,usr]);

//call streaming Function
    const callStreaming = useCallback((remoteId)=>{
    navigator.mediaDevices.getUserMedia({audio:true}).then((mediaStream)=>{
        stream = mediaStream;
        
        try{
            const call = peerRef.current.call(remoteId,mediaStream);
            callRef.current = call;
            call.on('stream',(remoteStream)=>{
                audioRef.current.srcObject = remoteStream;
                    //To avoid collision during multiple 
                    audioRef.current.addEventListener('loadedmetadata',()=>{
                        audioRef.current.play();
                    });
                });

        }catch(e){
            makeDecline();
        }        
    });

  },[makeDecline]);

    const makeLogin = useCallback(()=>{
        setLoading(true);
        WS.emit("login",token);
        setLoading(true);
    },[token]);

    const makeActive = useCallback(()=>{
        WS.emit("makeActive",!isActive);
        setActive(!isActive);  
    },[isActive]);



    const makeAnswer = useCallback(()=>{
        WS.emit("makeAnswer",remote.socketId);
        peerRef.current = peer;
        callStreaming(remote.peerId);
        setStatusCode(3);
        setStatus("Answered");
        stopRingtone();
        let min = 0;
        let sec = 0;
        timer = setInterval(()=>{
            let minStr = (min < 10) ? `0${min}`: `${min}`;
            let secStr = (sec < 10) ? `0${sec}`: `${sec}`;
            setTimeInf(`${minStr}:${secStr}`);
            sec += 1;
            if ( sec === 60){
                min += 1;
                sec = 0;
            }

        },1000); 
    },[remote,callStreaming]);

    

    

 

    const stopStream = ()=>{
        if(stream !== null){
            stream.getAudioTracks().forEach((audioTrack)=>{
                audioTrack.stop();
            });
        }
    };

   
 

    const closeModal = ()=>{
        setOpen(false);
    };

    



    

    useEffect(()=>{
        //Click Events
        peerRef.current = peer;
        switch(statusCode){
            case 0:
                refLogin.current.addEventListener('click',makeLogin);
                break;
            case 1:
                refLogin.current = null;
                refActive.current.addEventListener('click',makeActive);
                break;
            case 2:
                refAnswer.current.addEventListener('click',makeAnswer);
                refReject.current.addEventListener('click',makeDecline);
                break;
            case 3:
                refDecline.current.addEventListener('click',makeDecline);
                break;
            default:
                break;  
        }
        //peering
        peer.on('open',(id)=>{
            WS.emit("peer",id);
        });
        //is-log
        setInterval(()=>{
            WS.emit("relog",token);

        },1000);

        
        WS.on("is-log",(agent)=>{
                
                setStatusCode(1);      
                setStatus(`Hello ${agent}`);
                setActive(true);
                setUsr(agent);
                setStatusCode(1);
                setOpen(false);
                setLoading(false);
        });
        WS.on("is-log-failed",()=>{
            setOpen(true);
            setToken('');
            setLoading(false);
        });

        WS.on("reqLogout",()=>{
            window.location.reload();
        });

        WS.on("reqCall",(guest)=>{
            WS.emit("remote",guest.socketId);
            const{socketId,peerId,data} = guest;
            setRemote({
                socketId:socketId,
                peerId:peerId
            });
            setStatusCode(2);
            setStatus("Calling");
            if(data !== null){
                setGuestData({
                    name:data.name,
                    hotel:data.hotel,
                    room:data.room
                });
            }   
            playRingtone();      
        });

        WS.on("timeoutCall",()=>{
            if(!isAnswered){
                makeDecline();
            }
        });

        WS.on("reqReject", ()=>{
            setAnswered(false);
            setStatusCode(1);
            setStatus(`Hello ${usr}`);
            setRemote({
                socketId : "",
                peerId : ""
            });
            setGuestData({
                name : "",
                hotel: "",
                room: ""
            });
            stopRingtone();
        });

        WS.on("reqDecline", ()=>{
            clearInterval(timer);
            setAnswered(false);
            setStatusCode(1);
            setStatus(`Hello ${usr}`);
            setGuestData({
                name : "",
                hotel: "",
                room: ""
            });
            setRemote({
                socketId : "",
                peerId : ""
            });
            stopStream();
            stopRingtone();
        });
        WS.on("disconnect",()=>{
            window.location.reload();
            
        });

        

        
        
        


    },[isAnswered,makeActive,makeAnswer,makeDecline,makeLogin,statusCode,usr,token]);
    return(
    <div className="Page">
    
        <Receiver statusCode = {statusCode}
                  token={token} 
                  setToken={setToken}
                  refLogin={refLogin}
                  status={status}
                  isActive={isActive}
                  refActive={refActive}
                  guestData={guestData}
                  refAnswer={refAnswer}
                  refReject={refReject} 
                  refDecline={refDecline}
                  timeInf={timeInf}
                  isLoading={isLoading}
                  isOpen={isOpen}
                  closeModal={closeModal}/>
        
        <audio ref={audioRef} />


    </div>);

};

export default Agent;