// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  height: 100vh;
}

.Divider{
  background-color: #05659b;
  height: 0.5%;
}

.App-logo {
  height: 70vmin;
  pointer-events: none;
}

.Powered-logo{
  height: 40%;
  opacity: 0.7;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-zoom infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  height: 20%;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Footer{
  height: 9%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #05659b;
}

.PageLoad{
  height:70%;
}




@keyframes App-logo-zoom {
  0% {
    transform: scale(1, 1);
  }
    50% {
    transform: scale(1.2, 1.2);
  }
    100% {
    transform: scale(1, 1);
    }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,yBAAyB;AAC3B;;AAEA;EACE,UAAU;AACZ;;;;;AAKA;EACE;IACE,sBAAsB;EACxB;IACE;IACA,0BAA0B;EAC5B;IACE;IACA,sBAAsB;IACtB;AACJ","sourcesContent":[".App {\n  text-align: center;\n  height: 100vh;\n}\n\n.Divider{\n  background-color: #05659b;\n  height: 0.5%;\n}\n\n.App-logo {\n  height: 70vmin;\n  pointer-events: none;\n}\n\n.Powered-logo{\n  height: 40%;\n  opacity: 0.7;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-zoom infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #ffffff;\n  height: 20%;\n  min-height: 5vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.Footer{\n  height: 9%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  background-color: #05659b;\n}\n\n.PageLoad{\n  height:70%;\n}\n\n\n\n\n@keyframes App-logo-zoom {\n  0% {\n    transform: scale(1, 1);\n  }\n    50% {\n    transform: scale(1.2, 1.2);\n  }\n    100% {\n    transform: scale(1, 1);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
