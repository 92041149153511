import './Components.css';
import Info from './gadgets/Info';
import Cardguest from './gadgets/Cardguest';
import Callbefore from './gadgets/Callbefore';
import Oncall from './gadgets/Oncall';



const Dialer = (props)=>{
    const {statusCode,form,setForm,refSubmit,status,refCall, refDecline ,refReject,timeInf,isLoading,isOpen,closeModal} = props;
    const {name , hotel , room} = form;
    const {setName,setHotel,setRoom} = setForm;
    return(
    <div className="Component">
        {(statusCode === 0) ? (<Info name={name} setName={setName} hotel={hotel} setHotel={setHotel} room={room} setRoom={setRoom} refSubmit = {refSubmit} isLoading={isLoading} isOpen={isOpen} closeModal={closeModal}/>):
         (statusCode === 1) ?(
            <>
            <Cardguest status={status}/>
            <Callbefore refCall={refCall}/>
            </>):
         (statusCode === 2)?(
            <>
            <Cardguest status={status}/>
            <Oncall  refDecline={refReject}/>
            </>)
         :(statusCode === 3)?(
            <>
            <Cardguest status={status} timeInf={timeInf}/>
            <Oncall  refDecline={refDecline}/>
            </>)
         :(<Cardguest status={status}/>)
        }

    </div>);
    
};

export default Dialer;