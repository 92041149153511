import '../Components.css';
import Call from './svg/Call.svg';
import Decline from './svg/Decline.svg';

const Answerbefore = (props)=>{
    const {refAnswer , refReject} = props;
    return(
        <div className='Control'>
            <div><button className='Call' ref={refAnswer}><img src={Call} className="Card-log" alt="logo" /></button></div> 
            <div><button className='Decline'ref={refReject}><img src={Decline} className="Card-log" alt="logo" /></button></div>  

        </div>
    )
};

export default Answerbefore;
