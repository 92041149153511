import '../Components.css';
import Modal from 'react-modal';
import LoadingIcons from 'react-loading-icons';
const Info = (props)=>{
    const {name , setName ,hotel , setHotel , room , setRoom, refSubmit ,isLoading ,isOpen,closeModal} = props;
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          border: '2px solid #0000004d'
          
        },
      };
    

    return(
    <>
    <div className='Info'>
    <h1>Welcome to Hospitality Wifi Support Centre</h1>
    <h6>To proceed kindly fill out the form below</h6>
    <div className='label'><label>Guest Name</label></div>
    <input placeholder='Guest Name' value={name} onChange={
        (e)=> {
            e.preventDefault();
            setName(e.target.value);
        }
    }/>
    <div className='label'><label>Hotel Name</label></div>
    <input placeholder='Hotel Name'value={hotel} onChange={
        (e)=>{
            e.preventDefault();
            setHotel(e.target.value);
        }
    }/>
    <div className='label'><label>Hotel Room No</label></div>
    <input placeholder='Hotel Room No' type='number' value={room} onChange={
        (e)=>{
            e.preventDefault();
            setRoom(e.target.value);
        }
    }/>
    <p>We do not collect any personal information. We prioritize your privacy</p>
    <button className='submit' ref={refSubmit}>Submit</button>
    </div>
    {(isLoading)?(<LoadingIcons.Bars stroke="#05659b" className='Load'/>):(<></>)}


    <Modal isOpen={isOpen}
               onRequestClose={closeModal}
               contentLabel="Warning"
               style={customStyles}>
                <div className="Modal">
                <h4>Please fill all the fields</h4>
                <button className="Warning" onClick={closeModal}>Try again</button>
                </div>
    </Modal>
    </>
    
);


};

export default Info;