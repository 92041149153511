import '../Components.css';
import LoadingIcons from 'react-loading-icons';
import Modal from 'react-modal';

const Login = (props)=>{
    const {token,setToken,refLogin,isLoading,isOpen,closeModal} = props; 
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          border: '2px solid #0000004d'
          
        },
      };
    return(
        <>
        <div className='Info'>
        <h1>Agent Login</h1>
        <div className='label'><label>Access Token</label></div>
        <input placeholder='Paste Your Access Token Here' value={token} onChange={
            (e)=>{
                e.preventDefault();
                setToken(e.target.value);
            }
        }/>
        <button className='submit'ref={refLogin}>Login</button>
        </div>
        {(isLoading)?(<LoadingIcons.Bars stroke="#05659b" className='Load'/>):(<></>)}
        <Modal isOpen={isOpen}
               onRequestClose={closeModal}
               contentLabel="Warning"
               style={customStyles}>
                <div className="Modal">
                <h4>Invalid Login</h4>
                <button className="Warning" onClick={closeModal}>Try again</button>
                </div>
        </Modal>
        </>
    );
};

export default Login;