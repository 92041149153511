import './Components.css';
import Login from './gadgets/Login';
import Cardagent from './gadgets/Cardagent';
import Active from './gadgets/Active';
import Answerbefore from './gadgets/Answerbefore';
import Oncall from './gadgets/Oncall';

const Receiver = (props)=>{
    const {statusCode,token,setToken,refLogin, status ,isActive,refActive, guestData ,refAnswer , refReject, refDecline,timeInf,isLoading,isOpen,closeModal} = props;
    return(
        <div className='Component'>
            {(statusCode === 0) ? (<Login token={token} setToken={setToken} refLogin={refLogin} isLoading={isLoading} isOpen={isOpen} closeModal={closeModal}/>):
         (statusCode === 1) ?(
            <>
            <Cardagent status={status} guestData={guestData}/>
            <Active isActive={isActive} refActive={refActive}/>
            
            </>):
         (statusCode === 2)?(
            <>
            <Cardagent status={status} guestData={guestData}/>
            <Answerbefore refAnswer={refAnswer} refReject={refReject}/>
            
            
            </>)
         :(
            <>
            <Cardagent status={status} guestData={guestData} timeInf={timeInf}/>
            <Oncall refDecline={refDecline}/>
            </>)
        }

        </div>
    )
};

export default Receiver;




