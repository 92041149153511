import '../Components.css';
import Call from './svg/Call.svg';

const Callbefore = (props)=>{
    const {refCall} = props;
    return(
        <div className='Control'>
            <div><button className='Call' ref={refCall}><img src={Call} className="Card-log" alt="logo" /></button></div>  
        </div>
    )

};

export default Callbefore;